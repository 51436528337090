body {
  background-color: #f0f0f0;
  font-size: 16px;
  padding-bottom: 45px;

  @media (min-width: 1900px) {
    padding-bottom: 3.75vw;
  }
}

.statistik-main {
  //padding: 15px 0;

  //@media (min-width: 1024px) {
    padding: 30px 0;
  //}
}

.container {
  max-width: 100vw;
  margin: 0 auto;
  //padding: 0 15px;

  //@media (min-width: 1200px) {
    padding: 0 38px;
  //}

  .container {
    padding: 0;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
}

.not-found {
  padding: 40px 0;
  text-align: center;
}
