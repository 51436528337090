.login-holder {
  max-width: 430px;
  margin: 0 auto;
  background-color: #343a40;
  border-radius: 16px;
  color: #fff;
  padding: 20px;

  @media (min-width: 1024px) {
    padding: 48px;
  }

  .form-heading {
    text-align: center;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
      margin-bottom: 48px;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-outline {
    margin-bottom: 24px;
  }

  .input {
    display: block;
    width: 100%;
    margin: 0;
    background-color: #fff;
    border-radius: 5px;
    color: #495057;
    font-size: 20px;
    padding: 3px 8px;

    @media (min-width: 1024px) {
      padding: 8px 16px;
    }
  }

  .btn-holder {
    text-align: center;
  }

  .btn-submit {
    font-size: 20px;
    color: #fff;
    padding: 3px 8px;
    display: inline-block;
    vertical-align: middle;
    min-width: 148px;
    background-color: transparent;
    outline: none;
    border-radius: 5px;
    border: 1px solid #f8f9fa;
    cursor: pointer;
    transition: color .3s linear, background .3s linear, border .3s linear;

    @media (min-width: 1024px) {
      padding: 8px 16px;
    }

    &:hover {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
    }

    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
