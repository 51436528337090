.account-holder {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;

  .account {
    background-color: #dedbd5;
    padding: 20px;
    border-radius: 6px;
    text-align: center;
  }

  .account-image-block {
    display: block;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

    img {
      margin-bottom: 20px;
    }
  }

  .form-outline {
    margin-bottom: 24px;
  }

  textarea {
    display: block;
    width: 100%;
    margin: 0;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    color: #495057;
    font-size: 16px;
    padding: 3px 8px;
    text-align: left;
    resize: none;
    height: 100px;

    @media (min-width: 1024px) {
      padding: 8px 16px;
    }
  }

  .btn-holder {
    text-align: center;
  }

  .btn-submit {
    font-size: 20px;
    color: #000;
    padding: 3px 8px;
    display: inline-block;
    vertical-align: middle;
    min-width: 148px;
    background-color: transparent;
    outline: none;
    border-radius: 5px;
    border: 1px solid #000;
    cursor: pointer;
    transition: color .3s linear, background .3s linear, border .3s linear;

    @media (min-width: 1024px) {
      padding: 8px 16px;
    }

    &:hover {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
    }

    &.disable {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .image-area {
    position: relative;
    margin-right: 20px;
  }
  .remove-image {
    position: absolute;
    top: -130px;
    right: -15px;
    border-radius: 10em;
    padding: 2px 6px 3px;
    font: 700 21px/20px sans-serif;
    background: #555;
    border: 3px solid #fff;
    color: #FFF;
    box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3);
    text-shadow: 0 1px 2px rgba(0,0,0,0.5);
  }
  .remove-image:hover {
    background: #E54E4E;
    padding: 3px 7px 5px;
  }
  .remove-image:active {
    background: #E54E4E;
  }
}
