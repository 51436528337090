.statistik-wrapper {
  //font-family: Montserrat;
  .table-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @media (min-width: 1024px) {
      margin: 0 -1.66vw;
    }

    .table-col-lg,
    .table-col-sm {
      width: 100%;
      padding: 0 10px;
      margin-bottom: 20px;

      @media (min-width: 1024px) {
        width: 67%;
      }

      @media (min-width: 1200px) {
        padding: 0 1.66vw;
      }
    }

    .table-col-sm {
      @media (min-width: 1024px) {
        width: 33%;
      }
    }
  }
}

.slick-dots {
  bottom: calc(100% + 7px);
}
.slick-prev, .slick-next {
  //top: calc(50vh - 202px);
  color: #330164;
  width: 30px;
  height: 30px;
  z-index: 2;
  position: fixed;
  top: 50vh;
}
.slick-prev:before, .slick-next:before {
  color: #330164 !important;
  font-size: 30px;
}
.slick-next {
  right: 10px;
}
.slick-prev {
  left: 10px;
}
.additionalImages img {
  margin: 0 auto;
  width: auto;
  height: calc(100vh - 300px);
  max-width: 100%;

  @media (max-width: 768px) {
    height: auto;
  }
}