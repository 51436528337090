#header {
  background-color: #dedbd5;
  padding: 10px 0;
  -webkit-box-shadow: 0 0 13px 0px #00000078;
  -moz-box-shadow: 0 0 13px 0px #00000078;
  box-shadow: 0 0 8px 0px #00000078;

  @media (min-width: 1900px) {
    padding: 0.83vw 0;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }
}

.header-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    max-width: 100px;

    @media (min-width: 768px) {
      max-width: 200px;
    }

    @media (min-width: 1900px) {
      max-width: 16.667vw;
    }

    a {
      display: block;
    }

    img {
      width: 50vw;
    }
  }

  .nav {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 50%;
    text-align: right;

    @media (min-width: 1900px) {
      font-size: 1.33vw;
    }

    > li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      text-align: center;
    }

    a {
      color: #000;
      text-decoration: none;
      transition: color .3s linear;

      &:hover {
        color: #4b257b;
      }
    }
  }
}

.office-mode {
  display: flex;
  justify-content: flex-end;
}
