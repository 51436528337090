@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
.ticker-holder {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  line-height: 1;
  //width: 50%;
  //margin: 0 auto;
  background-color: #330164;
  color: #fff;

  @media (min-width: 1900px) {
    font-size: 3vw;
  }

  p {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    white-space: pre;
  }
}
