.statistik-table-holder {
  width: 100%;
  overflow: auto;
  border-radius: 4px;

  @media (min-width: 1900px) {
    border-radius: 0.33vw;
  }
}

.leader_board {
    .commission {
        padding: 0 34px;

        @media (max-width: 400px) {
          padding: 0 10px;
        }
        @media (max-width: 1024px) {
          text-align: right;
        }
    }
    .Omsättning-head {
      @media (max-width: 1024px) {
        text-align: right;
        padding-right: 20px;
      }
    }
}
@media (min-width: 575px) {
  .arrow {
    position: relative;
    background-image: url("https://live-backend.saljtavla.svenskamaklarhuset.se/Pil.png");
    background-repeat: no-repeat;
    background-size: 22px auto;
    background-position: left;
    display: inline-block;
    width: 22px;
    height: 22px;
    top: 5px;
    margin-left: -22px;
  }
}
.statistik-table {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 0;
  border-collapse: collapse;

  @media (min-width: 1900px) {
    box-shadow: 0 0.16vw 0.83vw rgba(0, 0, 0, 0.1);
  }

  //&.board {
  //  min-width: 500px;
  //}

  tbody {
    tr {
      &:nth-child(2n + 1) {
        background-color: rgba(222, 219, 213, 0.2);
      }
      .greenVal {
        color: green;
      }
    }
  }

  th {
    color: #8d8d8d;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: calc(17/14);
    text-align: center;
    padding: 5px;

    @media (min-width: 1024px) {
      padding: 15px 5px;
      height: 88px;
    }

    @media (min-width: 1900px) {
      font-size: 1.16vw;
      padding: 1.25vw 0.416vw;
      height: 7.33vw;
    }

    &:first-child {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: calc(29/24);
      color: #000;
      text-align: left;
      padding-left: 30px;
      text-transform: uppercase;

      @media (min-width: 1024px) {
        font-size: 24px;
      }

      @media (min-width: 1900px) {
        font-size: 2vw;
      }
      @media (max-width: 400px) {
        padding-left: 5px;
      }

    }
    &.Budget-head, &.fgår-head, &.Möten-head, &.Motbudget-head {
      @media (max-width: 585px) {
        display: none;
      }
    }

    &.Antalsålda-head,
    &.Kundmöte-head,
    &.Uppdrag-head, &.Sålda-head, &.Kommande-head, &.Visningar-head, &.total-hide-mob {
      @media (max-width: 585px) {
        display: none;
      }
    }
  }

  td {
    text-align: center;
    font-size: 14px;
    line-height: calc(17/14);
    color: #222;
    padding: 5px;

    @media (min-width: 1024px) {
      padding: 10px 5px;
      height: 55px;
    }

    @media (min-width: 1900px) {
      padding: 0.83vw 0.416vw;
      font-size: 0.9vw;
      height: 4.583vw;
    }


    //&.arrow {
    //  position: relative;
    //  &:after {
    //    @media (min-width: 575px) {
    //      position: absolute;
    //      right: -30px;
    //      background-image: url("https://phpstack-724978-2699069.cloudwaysapps.com/Pil.png");
    //    }
    //  }
    //}


    //&.arrow:after {
    //  position: absolute;
    //  right: -30px;
    //  background-image: url("https://phpstack-724978-2699069.cloudwaysapps.com/Pil.png");
    //}
    &.number {

      font-size: 18px;
      font-weight: 700;
      color: #4b257b;

      @media (min-width: 1024px) {
        width: 70px;
        font-size: 24px;
      }

      @media (min-width: 1900px) {
        width: 5.83vw;
        font-size: 2vw;
      }
      @media (min-width: 575px) {
        width: 40px;
      }
    }

    &.image {
      .person-image {
        width: 60px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        height: 60px;
        background-position-x: -20px;
        background-position-y: -5px;

        @media (min-width: 1900px) {
          width: 2.75vw;
        }

        img {
          display: block;
          margin: 0 auto;
          transform: scale(1.5);
          margin-top: 10px;
        }
      }
    }

    &.person {
      text-align: left;

      .person-office {
        display: block;
        color: #4b257b;
        font-weight: 500;

        @media (max-width: 450px) {
          word-break: break-word;
        }
      }
    }

    &.status.hide {
      opacity: 0;

    }
    &.tooltipwrapper {
      display: none;

    }
    &.status:not(.show) {
      //width: 0px !important;
      //height: 0px !important;
      //padding: 0px !important;
      //margin: 0px !important;
    }
    &.status:empty {
      //width: 0px;
      //padding: 0;
      //margin: 0;
      //display: none;
    }
    &.status img {
      opacity: 0;
      display: none;
    }
    &.status.rocket img.rocket-image {
      opacity: 1;
      display: block;
      width: 40px;
      min-width: 40px;
      height: 40px;
    }
    &.status .pokal-image {
      //font-size: 16px;
      position: relative;
      min-width: 40px;
      height: 40px;

      span {
        position: absolute;
        font-size: 14px;
        font-weight: bold;
        right: 4px;
        margin: auto;
        top: -3px;
        background-color: #330164;
        color: #fff;
        padding: 2px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }
      span:empty {
        display: none;
      }
    }
    &.status.cups .pokal-image {
      opacity: 1;
      display: block;

    }
    &.status img {
      padding: 3px;
      background: rgba(170, 102, 255, 0.3);
      border-radius: 50%;
    }

    &.rocket-image {
      padding: 3px;
      background: rgba(170, 102, 255, 0.3);
      border-radius: 50%;
    }

    &.status {
      width: 30px;

      @media (min-width: 1024px) {
        width: 70px;
      }

      @media (min-width: 1900px) {
        font-size: 5.83vw;
      }
    }
    &.viewings,
    &.kommande,
    &.soldEstateParts,
    &.budget, &.prev-month-commission, &.möten {
      @media (max-width: 585px) {
        display: none;
      }
    }
    &.status {
      @media (max-width: 585px) {
        width: 0;
        padding: 0;
        opacity: 0;
      }
    }

    &.commission {
      font-weight: 700;

      @media (min-width: 575px) {
        padding-right: 20px !important;
      }
    }
  }

  &.office_board, &.office_leader_board {
    .person {
      @media (max-width: 400px) {
        word-break: break-all;
      }
    }
  }
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #000000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #000000;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.loggedInAgent {
  background-color: rgb(80, 30, 112) !important;
}

.loggedInAgent td, .loggedInAgent .person-office {
  color: white!important;
}